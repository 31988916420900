const adObject = {
  adUnitCodesLogic: [
    {
      NotRenderedAdUnits: ['Billboard'],
      adUnit: 'skyscraper',
      status: 1,
    },
  ],
  adUnitCodes: [
    {
      classId: 'skyscraper',
      sizes: [[300, 1050], [300, 600], [160, 600], [161, 600], [120, 600], [1, 1]],
      breakingPoints:
        [
          [[0, 0], []],
          [[1200, 100], [[160, 600], [120, 600], [161, 600]]],
          [[1460, 100], [[300, 1050], [300, 600], [160, 600], [161, 600], [120, 600], [1, 1]]],
        ],
      adDeclaration: 0,
      lazyLoad: 0,
      expanded: true,
      collapse: true,
    }, {
      classId: 'Billboard',
      sizes: [[970, 250], [800, 250], [970, 90], [750, 200], [728, 90], 'fluid'],
      breakingPoints:
        [
          [[0, 0], []],
          [[700, 100], [[750, 200], [728, 90]]],
          [[1000, 100], [[970, 250], [800, 250], [970, 90], [728, 90]]],
        ],
      adDeclaration: 0,
      lazyLoad: 0,
      expanded: true,
      collapse: true,
    }, {
      classId: 'rectangle_1',
      sizes: [[300, 600], [300, 250], [250, 250]],
      breakingPoints:
        [
          [[0, 0], []],
          [[700, 100], [[300, 600], [300, 250], [250, 250]]],
        ],
      adDeclaration: 1,
      lazyLoad: 0,
      expanded: true,
      collapse: true,
    }, {
      classId: 'rectangle_2',
      sizes: [[300, 600], [300, 250], [250, 250]],
      breakingPoints:
        [
          [[0, 0], []],
          [[700, 100], [[300, 600], [300, 250], [250, 250]]],
        ],
      adDeclaration: 1,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'Content_1',
      sizes: [[640, 200], [640, 480], [300, 250], [1, 1]],
      breakingPoints:
        [
          [[0, 0], []],
          [[700, 100], [[640, 200], [640, 480], [300, 250], [1, 1]]],
        ],
      adDeclaration: 1,
      lazyLoad: 0,
      expanded: true,
      collapse: true,
    }, {
      classId: 'Content_2',
      sizes: [[640, 200], [300, 250]],
      breakingPoints:
        [
          [[0, 0], []],
          [[700, 100], [[640, 200], [300, 250]]],
        ],
      adDeclaration: 1,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'Content_3',
      sizes: [[640, 200], [300, 250]],
      breakingPoints:
        [
          [[0, 0], []],
          [[700, 100], [[640, 200], [300, 250]]],
        ],
      adDeclaration: 1,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'Content_4',
      sizes: [[640, 200], [300, 250]],
      breakingPoints:
        [
          [[0, 0], []],
          [[700, 100], [[640, 200], [300, 250]]],
        ],
      adDeclaration: 1,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'Content_5',
      sizes: [[640, 200], [300, 250]],
      breakingPoints:
        [
          [[0, 0], []],
          [[700, 100], [[640, 200], [300, 250]]],
        ],
      adDeclaration: 1,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'Mobile_Pos1',
      sizes: [[200, 200], [250, 250], [300, 50], [300, 75], [300, 250], [300, 300], [320, 100], [320, 50], [320, 160], [320, 320]],
      breakingPoints:
        [
          [[699, 100], []],
          [[0, 0], [[200, 200], [250, 250], [300, 50], [300, 75], [300, 250], [300, 300], [320, 100], [320, 50], [320, 160], [320, 320]]],
        ],
      adDeclaration: 1,
      lazyLoad: 0,
      expanded: true,
      collapse: true,
    }, {
      classId: 'Mobile_Pos2',
      sizes: [[200, 200], [250, 250], [300, 1], [300, 50], [300, 75], [300, 250], [300, 300], [300, 600], [320, 100], [320, 50], [320, 160], [320, 320], [320, 480], [1, 1], 'fluid'],
      breakingPoints:
        [
          [[699, 100], []],
          [[0, 0], [[200, 200], [250, 250], [300, 1], [300, 50], [300, 75], [300, 250], [300, 300], [300, 600], [320, 100], [320, 50], [320, 160], [320, 320], [320, 480], [1, 1]]],
        ],
      adDeclaration: 1,
      lazyLoad: 1,
      expanded: false,
      collapse: false,
    }, {
      classId: 'Mobile_Pos3',
      sizes: [[200, 200], [250, 250], [300, 50], [300, 75], [300, 250], [300, 300], [300, 600], [320, 100], [320, 50], [320, 160], [320, 320], 'fluid'],
      breakingPoints:
        [
          [[699, 100], []],
          [[0, 0], [[200, 200], [250, 250], [300, 50], [300, 75], [300, 250], [300, 300], [300, 600], [320, 100], [320, 50], [320, 160], [320, 320]]],
        ],
      adDeclaration: 1,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'Mobile_Pos4',
      sizes: [[200, 200], [250, 250], [300, 50], [300, 75], [300, 250], [300, 300], [300, 600], [320, 100], [320, 50], [320, 160], [320, 320], 'fluid'],
      breakingPoints:
        [
          [[699, 100], []],
          [[0, 0], [[200, 200], [250, 250], [300, 50], [300, 75], [300, 250], [300, 300], [300, 600], [320, 100], [320, 50], [320, 160], [320, 320]]],
        ],
      adDeclaration: 1,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'Mobile_Pos5',
      sizes: [[200, 200], [250, 250], [300, 50], [300, 75], [300, 250], [300, 300], [300, 600], [320, 100], [320, 50], [320, 160], [320, 320], 'fluid'],
      breakingPoints:
        [
          [[699, 100], []],
          [[0, 0], [[200, 200], [250, 250], [300, 50], [300, 75], [300, 250], [300, 300], [300, 600], [320, 100], [320, 50], [320, 160], [320, 320]]],
        ],
      adDeclaration: 1,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'Mobile_Pos6',
      sizes: [[200, 200], [250, 250], [300, 50], [300, 75], [300, 250], [300, 300], [300, 600], [320, 100], [320, 50], [320, 160], [320, 320], 'fluid'],
      breakingPoints:
        [
          [[699, 100], []],
          [[0, 0], [[200, 200], [250, 250], [300, 50], [300, 75], [300, 250], [300, 300], [300, 600], [320, 100], [320, 50], [320, 160], [320, 320]]],
        ],
      adDeclaration: 1,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'nativead_1',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'nativead_2',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'nativead_3',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'nativead_4',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'footerad_1',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'footerad_2',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'footerad_3',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'empfehlungen_1',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'empfehlungen_2',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'empfehlungen_3',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'produkt_1',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'produkt_2',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'produkt_3',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'produkt_4',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'produkt_5',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'produkt_6',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'produkt_7',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'produkt_8',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'performance_campaign',
      sizes: 'fluid',
      breakingPoints: [],
      adDeclaration: 1,
      lazyLoad: 1,
      expanded: true,
      collapse: true,
    }, {
      classId: 'OutOfPage',
      type: 'Out-of-Page',
      adDeclaration: 0,
      lazyLoad: 0,
      expanded: true,
      collapse: true,
    }, {
      classId: 'recommendation_feed',
      sizes: [[3, 3]],
      breakingPoints: [],
      adDeclaration: 0,
      lazyLoad: 0,
      expanded: true,
      collapse: true,
    },
  ],
  configVersion: '4.17.04.01',
  adDomain: 'utopia_de',
  adProvider: 'utx',
  mediaSize: 1,
  enableLazyLoad: 0,
  headerBidding: 1,
  bidTimeout: 1000,
  currency: 'EUR',
  stickySkyscraperTop: '48px',
  id5: [
    {
      id5Status: 1,
      id5PartnerID: 1198,
    },
  ],
  incubatorStatus: 1,
  incubator: [
    ['postID', 175635], // https://utopia.de/ratgeber/jetzt-als-doku-erster-getraenkemarkt-der-einwegplastik-komplett-verbannt/
  ],
  integration: [
    ['pagePostType', 'index_category'],
    ['pagePostType', 'index_tag'],
    ['pagePostType', 'post'],
    ['pagePostType', 'news'],
    ['pagePostType', 'utopia_news'],
    ['pagePostType', 'ratgeber'],
    ['pagePostType', 'utopia_ratgeber'],
    ['pagePostType', 'galerien'],
    ['pagePostType', 'utopia_gallery'],
    ['bart', 'artikel'],
    ['bart', 'liste'],
    ['bart', 'galerie'],
    ['bart', ''],
  ],
  cmp: [
    {
      tcfStatus: 1,
      tcfVersion: 2.2,
    },
  ],
  stickyAds: [
    {
      stickyAdStatus: 0,
      stickyAdUnitRectangle_1: 'rectangle_1',
      stickyAdUnitContent_1: 'Content_1',
    },
  ],
  adnami: [
    {
      adnamiStatus: 1,
      adnamiJS: 'https://macro.adnami.io/macro/hosts/adsm.macro.utopia.de.js',
    },
  ],
  dmp_adex: [
    {
      dmp_adex_status: 1,
      dmp_adex_host: 'https://dmp.theadex.com/d',
      dmp_adex_js: 's/adex.js',
      dmp_adex_cid: 1984,
      dmp_adex_tid: 7852,
      dmp_adex_kw: [],
    },
  ],
  dmp_1plusx: [
    {
      dmp_1plusxStatus: 0,
      dmp_1plusxJS: 'https://cdn.opecloud.com/ope-commonmedia.js',
      dmp_1plusxKW: ['not_set'],
    },
  ],
  userLogin: [
    {
      userLoginStatus: 1,
      login: false,
    },
  ],
  iab_cats: [
    {
      iab_cats_status: 0,
      ut_cat: [ { } ],
    },
  ],
  pb: [
    {
      pbStatus: 1,
      pbScript: 'https://utopix.net/dist/pb_8_42_0_cur_fpd_gdpr_id5_pan_sha_ram_uti.js',
      pbScriptDev: 'https://localhost:3443/dist/pb_8_42_0_cur_fpd_gdpr_id5_pan_sha_ram_uti.js',
      consentManagement: [ {
        cmpApi: 'iab',
        gdpr_timeout: 2000,
        ccpa_timeout: 100,
      } ],
      pbSharedIdPixelURL: 'https://utopia.de/worker/cky/sharedid',
      pbConfig: [],
      pbUserIds: [],
      pbUtiq: {
        status: 1,
        utiqLoaderURL: 'https://utiq.utopia.de/utiqLoader.js',
      },
      pbLiveRamp: {
        name: 'identityLink',
        params: {
          pid: '999',
          notUse3P: false,
        },
        storage: {
          type: 'cookie',
          name: 'idl_env',
          expires: 15,
          refreshInSeconds: 1800,
        },
      },
      pbAnalyticsStatus: 0,
      pbAnalyticsID: 'UA-153812394-1',
      pbSublimeStatus: 1,
      pbSublimeScript: 'https://sac.ayads.co/sublime/33506/prebid',
      pbSublimeZoneId: 33506,
      pbSlotsDesktopList: [ 'skyscraper', 'Billboard', 'Content_1', 'Content_2', 'Content_3', 'Content_4', 'Content_5', 'rectangle_1', 'rectangle_2' ],
      pbSlotsMobileList: [ 'Mobile_Pos2', 'Mobile_Pos3', 'Mobile_Pos4', 'Mobile_Pos5', 'Mobile_Pos6' ],
      pbSlotsDesktopIncubator: [ {} ],
      pbSlotsDesktopSkins: [ {
        code: 'skyscraper',
        mediaTypes: {
          banner: {
            sizes: [ [1, 1] ],
          },
        },
        bids: [ {
          bidder: 'visx',
          params: {
            uid: '917494',
          },
        }, {
          bidder: 'dspx',
          params: {
            placement: '2702',
          },
        }, {
          bidder: 'gumgum',
          params: {
            zone: 'u11tijr2',
            product: 'skins',
          },
        } ],
      } ],
      pbSlotsDesktop: [ {
        code: 'skyscraper',
        mediaTypes: {
          banner: {
            sizes: [ [120, 600], [160, 600] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504122-8',
          },
        }, {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504122-9',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914064',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837591,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '615416767',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: '92936bc1-83fc-42bd-9c18-88caa1797cda',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_skyscraper_300x600_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15035598',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'skyscraper',
            placement: 'skyscraper',
          },
        } ],
      }, {
        code: 'Billboard',
        mediaTypes: {
          banner: {
            sizes: [ [468, 60], [728, 90], [970, 90], [970, 250] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504120-1',
          },
        }, {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504120-2',
          },
        }, {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504120-55',
          },
        }, {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504120-57',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914055',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837592,
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837593,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '778387787',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: '4ac9c75d-3adf-4c3e-811f-1e4114cd2b55',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_billboard_970x250_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15035601',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Billboard',
            placement: 'Billboard',
          },
        } ],
      }, {
        code: 'Content_1',
        mediaTypes: {
          video: {
            sizes: [ [1, 1] ],
            context: 'outstream',
          },
        },
        bids: [ {
          bidder: 'impactify',
          params: {
            appId: 'utopia.de',
            format: 'screen',
            style: 'impact',
          },
        } ],
      }, {
        code: 'Content_1',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250] ],
          },
          video: {
            context: 'outstream',
            playerSize: [1, 1],
          },
        },
        bids: [ {
          bidder: 'teads',
          params: {
            placementId: 122982,
            pageId: 113140,
          },
        } ],
      }, {
        code: 'Content_1',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504128-15',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914059',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837594,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '212741769',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: '88edeb95-356b-41ea-b79c-3c1d2ff4c110',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_content_300x250_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15035608',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Content_1',
            placement: 'Content_1',
          },
        } ],
      }, {
        code: 'Content_2',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504130-15',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914060',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837595,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '932153248',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: 'c3e42b3b-9836-4265-9340-85d915fdd686',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_content_300x250_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15035609',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Content_2',
            placement: 'Content_2',
          },
        } ],
      }, {
        code: 'Content_3',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504132-15',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914061',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837596,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '512685168',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: '1f4b640c-1388-4bc7-856d-f063ef5f6cdf',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_content_300x250_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15035610',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Content_3',
            placement: 'Content_3',
          },
        } ],
      }, {
        code: 'Content_4',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504628-15',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914062',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837597,
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_content_300x250_pb',
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '228800106',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: '9d18c092-8cbb-42fa-a8bd-cfd10fb29efb',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_content_300x250_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15035611',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Content_4',
            placement: 'Content_4',
          },
        } ],
      }, {
        code: 'Content_5',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504630-15',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914063',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837598,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '234680385',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: '25071e5b-d078-4ff7-8fc5-652cfc8de6fe',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_content_300x250_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15035612',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Content_5',
            placement: 'Content_5',
          },
        } ],
      }, {
        code: 'rectangle_1',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250], [300, 600] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504124-15',
          },
        }, {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504124-10',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914057',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837600,
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837601,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '764566449',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: 'ae481de4-fb2b-454d-9121-ba428cd8f8ad',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_rectangle_300x600_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15035614',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Rectangle_1',
            placement: 'Rectangle_1',
          },
        } ],
      }, {
        code: 'rectangle_2',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250], [300, 600] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504126-15',
          },
        }, {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504126-10',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914058',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837602,
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837603,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '830785258',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: 'ef74cc05-87dd-4294-bb99-98a2c6ca19aa',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_rectangle_300x600_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15035615',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Rectangle_2',
            placement: 'Rectangle_2',
          },
        } ],
      } ],
      pbSlotsMobileIncubator: [ {
        code: 'Mobile_Pos2',
        mediaTypes: {
          banner: {
            sizes: [ [1, 1] ],
          },
        },
        bids: [ {
          bidder: 'dspx',
          params: {
            placement: '2700',
            pfilter: {
              injTagId: 'Mobile_Pos2',
            },
          },
        } ],
      } ],
      pbSlotsMobile: [ {
        code: 'Mobile_Pos2',
        mediaTypes: {
          banner: {
            sizes: [ [1, 1] ],
          },
        },
        bids: [ {
          bidder: 'gumgum',
          params: {
            zone: 'u11tijr2',
          },
        } ],
      }, {
        code: 'Mobile_Pos2',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250] ],
          },
        },
        bids: [ {
          bidder: 'gumgum',
          params: {
            zone: 'u11tijr2',
            slot: '1045922',
          },
        } ],
      }, {
        code: 'Mobile_Pos2',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250], [300, 600], [320, 480] ],
          },
        },
        bids: [ {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '136127030',
          },
        } ],
      }, {
        code: 'Mobile_Pos2',
        mediaTypes: {
          video: {
            sizes: [ [1, 1] ],
            context: 'outstream',
          },
        },
        bids: [ {
          bidder: 'impactify',
          params: {
            appId: 'utopia.de',
            format: 'screen',
            style: 'impact',
          },
        } ],
      }, {
        code: 'Mobile_Pos2',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250] ],
          },
          video: {
            context: 'outstream',
            playerSize: [1, 1],
          },
        },
        bids: [ {
          bidder: 'teads',
          params: {
            placementId: 122983,
            pageId: 113141,
          },
        } ],
      }, {
        code: 'Mobile_Pos2',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250], [300, 600] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504632-15',
          },
        }, {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504632-10',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914040',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837604,
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837605,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: '46a9163c-a240-4f36-a156-401dac4ec217',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_mobile_pos_300x600_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15035625',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Mobile_Pos2',
            placement: 'Mobile_Pos2',
          },
        } ],
      }, {
        code: 'Mobile_Pos3',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250], [300, 600] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504634-15',
          },
        }, {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504634-10',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914041',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837607,
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837606,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '918822444',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: 'd62846cb-0282-44ac-a63a-b6689c436b89',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_mobile_pos_300x600_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15049317',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Mobile_Pos3',
            placement: 'Mobile_Pos3',
          },
        } ],
      }, {
        code: 'Mobile_Pos4',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250], [300, 600] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504636-15',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914042',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837608,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '935602859',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: 'c8816047-d911-4a1f-9753-b3e33017d8ec',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_mobile_pos_300x600_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15049318',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Mobile_Pos4',
            placement: 'Mobile_Pos4',
          },
        } ],
      }, {
        code: 'Mobile_Pos5',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250], [300, 600] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504638-15',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914043',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837609,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '186421386',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: '29cda8aa-3333-4f67-830e-492b92c63723',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_mobile_pos_300x250_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15049319',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Mobile_Pos5',
            placement: 'Mobile_Pos5',
          },
        } ],
      }, {
        code: 'Mobile_Pos6',
        mediaTypes: {
          banner: {
            sizes: [ [300, 250], [300, 600] ],
          },
        },
        bids: [ {
          bidder: 'rubicon',
          params: {
            accountId: '21696',
            siteId: '298992',
            zoneId: '1504640-15',
          },
        }, {
          bidder: 'visx',
          params: {
            uid: '914044',
          },
        }, {
          bidder: 'sovrn',
          params: {
            tagid: 837610,
          },
        }, {
          bidder: 'onetag',
          params: {
            pubId: '7a2f180ee6ab245',
          },
        }, {
          bidder: 'medianet',
          params: {
            cid: '8CU5UTXH6',
            crid: '561028227',
          },
        }, {
          bidder: 'stroeerCore',
          params: {
            sid: 'b1f58c31-8db7-4447-b82c-5448ef895fed',
          },
        }, {
          bidder: 'triplelift',
          params: {
            inventoryCode: 'utopia-de_mobile_pos_300x250_pb',
          },
        }, {
          bidder: 'yieldlab',
          params: {
            supplyId: '14918889',
            adslotId: '15049320',
          },
        }, {
          bidder: 'adagio',
          params: {
            organizationId: '1405',
            site: 'utopia-de',
            adUnitElementId: 'Mobile_Pos6',
            placement: 'Mobile_Pos6',
          },
        } ],
      } ],
    },
  ],
  aps: [
    {
      apsStatus: 1,
      apsPubID: '41f82885-7b6b-40bd-8b94-c275bfb0e639',
      apsAdServer: 'googletag',
      apsPrivacy: '1--',
      apsCmpTimeout: 500,
      apsSlotsDesktop: [ {
        slotID: 'Billboard',
        slotName: '/43287570/utopia/_default',
        sizes: [ [728, 90], [970, 250] ],
      }, {
        slotID: 'skyscraper',
        slotName: '/43287570/utopia/_default',
        sizes: [ [160, 600], [120, 600] ],
      }, {
        slotID: 'rectangle_1',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250], [300, 600] ],
      }, {
        slotID: 'rectangle_2',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250], [300, 600] ],
      }, {
        slotID: 'Content_1',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250] ],
      }, {
        slotID: 'Content_2',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250] ],
      }, {
        slotID: 'Content_3',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250] ],
      }, {
        slotID: 'Content_4',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250] ],
      }, {
        slotID: 'Content_5',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250] ],
      } ],
      apsSlotsMobile: [ {
        slotID: 'Mobile_Pos2',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250], [300, 600] ],
      }, {
        slotID: 'Mobile_Pos3',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250], [300, 600] ],
      }, {
        slotID: 'Mobile_Pos4',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250] ],
      }, {
        slotID: 'Mobile_Pos5',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250] ],
      }, {
        slotID: 'Mobile_Pos6',
        slotName: '/43287570/utopia/_default',
        sizes: [ [300, 250] ],
      } ],
    },
  ],
  networkCode: '43287570',
  publisherDfpAdUrl: '/43287570/utopia',
  adsense: [
    {
      automaticAds: 0,
      adsenseClientId: 'pub-8463774791716939',
      adsenseClientCAId: 'ca-pub-8463774791716939',
    },
  ],
  blacklistUrls: {
    '/AdLib/demo.html': 2,
    '/film-dvd-embrace-du-bist-schoen-50818/': 1,
    '/liebeskugeln-oeko-test-99146/': 1,
    '/ratgeber/absurdeste-produkte-fuer-frauen/': 1,
    '/ratgeber/gebrauchte-moebel-3-webseiten-fuer-second-hand-moebel/': 1,
    '/ratgeber/green-sex-vegan-tipps-nachhaltiges-liebesleben/': 1,
    '/ratgeber/schleim-selber-machen-ohne-kleber-rezept-mit-natuerlichen-zutaten/': 1,
    '/soko-tierschutz-tierversuche-lpt-160427/': 1,
    '/hund-grill-kampagne-peta-143418/': 1,
    '/ratgeber/mehr-verlage-stellen-in-schulbuechern-endlich-die-ganze-klitoris-dar-/': 1,
  },
};

export default adObject;
